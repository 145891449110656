export const profileActionTypes = {
   // GET CURRENT USER TYPES
   GET_CURRENT_USER_INFO_START: "GET_CURRENT_USER_INFO_START",
   GET_CURRENT_USER_INFO_SUCCESS: "GET_CURRENT_USER_INFO_SUCCESS",
   GET_CURRENT_USER_INFO_FAILURE: "GET_CURRENT_USER_INFO_FAILURE",

   // EDIT AVATAR TYPES
   EDIT_AVATAR_START: "EDIT_AVATAR_START",
   EDIT_AVATAR_SUCCESS: "EDIT_AVATAR_SUCCESS",
   EDIT_AVATAR_FAILURE: "EDIT_AVATAR_FAILURE",

   // UPDATE USER TYPES
   UPDATE_USER_START: "UPDATE_USER_START",
   UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
   UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

   // UPDATE LINK TYPES
   UPDATE_LINK_START: "UPDATE_LINK_START",
   UPDATE_LINK_SUCCESS: "UPDATE_LINK_SUCCESS",
   UPDATE_LINK_FAILURE: "UPDATE_LINK_FAILURE",

   // SUBSCRIBE LINK TYPES
   SUBSCRIBE_LINK_START: "SUBSCRIBE_LINK_START",
   SUBSCRIBE_LINK_SUCCESS: "SUBSCRIBE_LINK_SUCCESS",
   SUBSCRIBE_LINK_FAILURE: "SUBSCRIBE_LINK_FAILURE",
};
