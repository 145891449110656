export const unitsActionTypes = {
   // SET SELECTED UNIT
   SET_SELECTED_UNIT: "SET_SELECTED_UNIT",

   // GET SINGLE UNIT TYPES
   GET_SINGLE_UNIT_START: "GET_SINGLE_UNIT_START",
   GET_SINGLE_UNIT_SUCCESS: "GET_SINGLE_UNIT_SUCCESS",
   GET_SINGLE_UNIT_FAILURE: "GET_SINGLE_UNIT_FAILURE",

   // CREATE UNIT TYPES
   CREATE_UNIT_START: "CREATE_UNIT_START",
   CREATE_UNIT_SUCCESS: "CREATE_UNIT_SUCCESS",
   CREATE_UNIT_FAILURE: "CREATE_UNIT_FAILURE",

   // EDIT UNIT TYPES
   EDIT_UNIT_START: "EDIT_UNIT_START",
   EDIT_UNIT_SUCCESS: "EDIT_UNIT_SUCCESS",
   EDIT_UNIT_FAILURE: "EDIT_UNIT_FAILURE",

   // DELETE UNIT TYPES
   DELETE_UNIT_START: "DELETE_UNIT_START",
   DELETE_UNIT_SUCCESS: "DELETE_UNIT_SUCCESS",
   DELETE_UNIT_FAILURE: "DELETE_UNIT_FAILURE",

   // ADD UNIT VOICE TYPES
   ADD_VOICE_START: "ADD_VOICE_START",
   ADD_VOICE_SUCCESS: "ADD_VOICE_SUCCESS",
   ADD_VOICE_FAILURE: "ADD_VOICE_FAILURE",

   // REMOVE UNIT VOICE TYPES
   DELETE_VOICE_START: "DELETE_VOICE_START",
   DELETE_VOICE_SUCCESS: "DELETE_VOICE_SUCCESS",
   DELETE_VOICE_FAILURE: "DELETE_VOICE_FAILURE",
};
