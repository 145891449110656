export const authActionTypes = {
   // LOGIN TYPES
   LOGIN_START: "AUTH_ASYNC_START",
   LOGIN_SUCCESS: "LOGIN_SUCCESS",
   LOGIN_FAILURE: "LOGIN_FAILURE",

   // REGISTER TYPES
   REGISTER_START: "REGISTER_START",
   REGISTER_SUCCESS: "REGISTER_SUCCESS",
   REGISTER_FAILURE: "REGISTER_FAILURE",

   // RESET PASSWORD TYPES
   RESET_PASSWORD_START: "RESET_PASSWORD_START",
   RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
   RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

   // SET PASSWORD TYPES
   SET_PASSWORD_START: "SET_PASSWORD_START",
   SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
   SET_PASSWORD_FAILURE: "SET_PASSWORD_FAILURE",

   //LOG OUT TYPES
   USER_LOG_OUT: "USER_LOG_OUT",
   PROPERTY_CHANGE: "PROPERTY_CHANGE",
};
