import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

//BASE COMPONENTS
import GridContainer from "components/grid-container/GridContainer.component";
import GridItem from "components/grid-item/GridItem.component";
import BackArrow from "components/back-arrow/BackArrow.component";
import Form from "components/form/Form.component";
import TextArea from "components/text-area/TextArea.component";
import TagsInput from "components/tags-input/TagsInput.component";
import Button from "components/button/Button.component";
import Select from "components/select/Select.component";
import DropZone from "components/drop-zone/DropZone.component";
import Modal from "components/modal/Modal.component";
//EFFECTS
import useInput from "effects/useInput.effect";
//ACTIONS
import { getLevelsListAsync } from "redux/common/common.actions";
import { getSingleTopicAsync } from "redux/topics/topics.actions";
import {
   getSingleUnitAsync,
   createUnitAsync,
   editUnitAsync,
} from "redux/units/units.actions";
//SERVICES
import AddEditUnitServices from "./add-edit-unit.services";
//UTILITIES
import { checkForEmptyProperties } from "utilities/helper-functions";

import RecordAudioModal from "./components/RecordAudioModal.component";

const AddEditUnitSubpage = (props) => {
   const {
      getLevelsListAsync,
      levelsList,
      getSingleTopicAsync,
      singleTopicData,
      getSingleUnitAsync,
      createUnitAsync,
      editUnitAsync,
      unitCreateLoading,
      unitEditLoading,
      selectedUnit,
      voiceAddLoading,
      deleteVoiceLoading,
   } = props;
   const { generateLevelsOptions } = AddEditUnitServices;
   const singleUnitDataCopy = { ...selectedUnit };
   const history = useHistory();

   const [isTagsUpdated, changeIsTagsUpdated] = useState(false);
   const [fileData, setFileData] = useState({});
   const [isAudioModal, toggleAudioModal] = useState(false);

   const levelsOptions = generateLevelsOptions(levelsList || []);
   let { topicID, unitID } = useParams();
   const files = unitID ? singleUnitDataCopy?.voices : [];
   const [uploadedFiles, setUploadedFiles] = useState(files);

   useEffect(() => {
      getLevelsListAsync();
      if (topicID) {
         getSingleTopicAsync(topicID);
      }
      if (unitID) {
         getSingleUnitAsync(unitID);
      }
      //eslint-disable-next-line
   }, [])

   const formInitState = {
      description: "",
      level: levelsOptions[0],
      subject: singleTopicData?.id,
      tags: [],
      translation: "",
      value: "",
      voices: [],
   };

   const levelDefaultValue = unitID
      ? {
           ...selectedUnit?.level,
           label: selectedUnit?.level?.value,
        }
      : levelsOptions[0];

   const formState = unitID
      ? {
           ...singleUnitDataCopy,
           tags: singleUnitDataCopy?.tags,
           voices: [],
        }
      : formInitState;

   const {
      inputState,
      handleInput,
      handleInvalidMessage,
      invalidMessages,
   } = useInput({ ...formState });

   const handleInputChange = (event) => {
      handleInput(event);
   };

   const handleSelecetedTags = (items) => {
      const tags = {
         target: { name: "tags", value: items },
      };

      changeIsTagsUpdated(true);
      handleInput(tags);
   };

   const onSelectChange = (event, levelType) => {
      const e = { ...event };
      delete e.label;

      //for input state for select component
      const selectState = {
         target: { name: levelType, value: e },
      };
      handleInput(selectState);
   };

   const handleFiles = (files) => {
      getBase64(files[0]);
      setUploadedFiles(files);
   };

   function getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
         setFileData({
            data: reader.result,
            language: singleTopicData?.foreignLanguage,
            name: file?.name,
         });
      };
      reader.onerror = function(error) {
         console.log("Error: ", error);
      };
   }

   const onSubmit = (e) => {
      e.preventDefault();
      if (unitID) {
         editUnitAsync(
            unitID,
            inputState,
            history,
            topicID,
            isTagsUpdated,
            fileData,
            singleUnitDataCopy?.voices[0]?.id
         );
      } else {
         createUnitAsync(topicID, inputState, history, fileData);
      }
   };

   const onCancell = (e) => {
      e.preventDefault();
      history.push(`/topics/${topicID}/units`);
   };

   return (
      <div className="add-edit-unit">
         <Modal
            handleModalClose={() => toggleAudioModal(false)}
            isOpen={isAudioModal}>
            <RecordAudioModal
               onCancell={() => toggleAudioModal(false)}
               unitID={unitID}
               topicID={topicID}
               language={singleTopicData?.foreignLanguage}
               prevVoiceID={singleUnitDataCopy?.voices[0]?.id}
            />
         </Modal>
         <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
               <BackArrow text="Юниты" />
               <h1>Новый юнит</h1>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
               <Form>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                     <TextArea
                        name="value"
                        value={inputState.value}
                        error={invalidMessages}
                        onChange={handleInputChange}
                        onInvalid={handleInvalidMessage}
                        label="Изучаемый язык"
                        placeholder="If you are silent people don’t understand what you feel"
                        minRows={2}
                        required
                     />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                     <TextArea
                        name="translation"
                        value={inputState.translation}
                        error={invalidMessages}
                        onChange={handleInputChange}
                        onInvalid={handleInvalidMessage}
                        label="Перевод на родной или объяснения на изучаемом языке"
                        placeholder="Если вы молчите, люди не понимают, что вы чувствуете"
                        minRows={2}
                        required
                     />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                     <TextArea
                        name="description"
                        value={inputState.description || ""}
                        error={invalidMessages}
                        onChange={handleInputChange}
                        onInvalid={handleInvalidMessage}
                        label="Дополнительные пояснения"
                        placeholder="Это предложение очень важно для меня"
                        minRows={2}
                        required
                     />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                     <TagsInput
                        selectedTags={handleSelecetedTags}
                        fullWidth
                        variant="outlined"
                        id="tags"
                        name="tags"
                        placeholder="#hashtags"
                        label="Теги"
                     />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} lg={3}>
                     <Select
                        name="level"
                        label="Сложность"
                        options={levelsOptions}
                        defaultValue={levelDefaultValue}
                        onChange={(e) => onSelectChange(e, "level")}
                        placeholder="Сложность"
                     />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                     <DropZone
                        title="или перенесите аудиофайл сюда"
                        buttonText="Добавьте"
                        handleFiles={handleFiles}
                        files={uploadedFiles}
                        buttonAction={() => toggleAudioModal(true)}
                     />
                  </GridItem>
                  <GridItem
                     xs={12}
                     sm={12}
                     md={6}
                     lg={6}
                     className="new-topic-subpage__buttons-block">
                     <GridItem xs={12} sm={12} md={2} lg={2}>
                        <Button
                           isLoading={
                              unitCreateLoading ||
                              unitEditLoading ||
                              voiceAddLoading ||
                              deleteVoiceLoading
                           }
                           className="save-button"
                           disabled={
                              !checkForEmptyProperties(inputState, [
                                 "description",
                              ]) || uploadedFiles.length <= 0
                           }
                           onClick={onSubmit}>
                           Сохранить
                        </Button>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={2} lg={2}>
                        <Button onClick={onCancell} className="cancel-button">
                           Отмена
                        </Button>
                     </GridItem>
                  </GridItem>
               </Form>
            </GridItem>
         </GridContainer>
      </div>
   );
};

const mapStateToProps = (state) => {
   const { common, topics, units } = state;
   return {
      levelsList: common.levelsList,
      singleTopicData: topics.singleTopicData,
      singleUnit: units.singleUnit,
      unitCreateLoading: units.unitCreateLoading,
      unitEditLoading: units.unitEditLoading,
      selectedUnit: units.selectedUnit,
      voiceAddLoading: units.voiceAddLoading,
      deleteVoiceLoading: units.deleteVoiceLoading,
   };
};

const mapDispatchToProps = (dispatch) => ({
   getLevelsListAsync: () => dispatch(getLevelsListAsync()),
   getSingleTopicAsync: (topicID) => dispatch(getSingleTopicAsync(topicID)),
   getSingleUnitAsync: (unitID) => dispatch(getSingleUnitAsync(unitID)),
   createUnitAsync: (topicID, params, history, voiceParams, prevVoiceID) =>
      dispatch(
         createUnitAsync(topicID, params, history, voiceParams, prevVoiceID)
      ),
   editUnitAsync: (
      unitID,
      formParams,
      history,
      topicID,
      isTagsUpdated,
      voiceParams,
      prevVoiceID
   ) =>
      dispatch(
         editUnitAsync(
            unitID,
            formParams,
            history,
            topicID,
            isTagsUpdated,
            voiceParams,
            prevVoiceID
         )
      ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUnitSubpage);
