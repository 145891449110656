export const commonActionTypes = {
   // GET LANGUAGES TYPES
   GET_LANGUAGES_LIST_START: "GET_LANGUAGES_LIST_START",
   GET_LANGUAGES_LIST_SUCCESS: "GET_LANGUAGES_LIST_SUCCESS",
   GET_LANGUAGES_LIST_FAILURE: "GET_LANGUAGES_LIST_FAILURE",

   // GET LEVELS TYPES
   GET_LEVELS_LIST_START: "GET_LEVELS_LIST_START",
   GET_LEVELS_LIST_SUCCESS: "GET_LEVELS_LIST_SUCCESS",
   GET_LEVELS_LIST_FAILURE: "GET_LEVELS_LIST_FAILURE",
};
